import apiClient from "@/api/api";
import { Device } from '@capacitor/device';
import { Network } from "@capacitor/network";
// import { currentHzba } from "@/store/currentHzba";
import 'setimmediate';
import * as winston from "winston";
import Transport from 'winston-transport';
import { environment } from "../../environments/environment";



export const logger = winston.createLogger({
    format: winston.format.json(),
    defaultMeta: {
        // Define keywords that are associated to every log in OpenSearch
        tag: 'ACON360_FRONTEND_LOGGING',
        environment: undefined,
        currentBa: undefined,
        immoId: undefined,
        userId: undefined,
        deviceID: undefined,
        device: undefined,
        jwt: undefined,
    },
    transports: [],
});

class ATransporter extends Transport {
  constructor() {
    super();
  }

  async log(info: any, callback: any) {
    const status = await Network.getStatus();
    // eslint-disable-next-line no-constant-condition
    // if (false) {
    info.date = new Date();
    if (status.connected) {
        try {
            const logsStr = localStorage.getItem("LOCAL_LOGS");
            if (logsStr) {
                const logs = JSON.parse(logsStr!);
                logs.forEach((log: any) => apiClient.post("/other/log", {...log, isLocal: true, uploadedAt: new Date() }));
                localStorage.removeItem("LOCAL_LOGS");
            }
        } catch (error) {
            console.error("Error loading offline logs", {"error": error, "value": localStorage.getItem("LOCAL_LOGS")});

            localStorage.removeItem("LOCAL_LOGS");
        }
        // console.debug(`New Log: ${info.message}`, info);//, info);
        try {
            await apiClient.post("/other/log", info);
        } catch(err) {
            console.error('logging failed', err)
            // logger.error(`Logging failed: ${err}`);

        }
    } else {
        // SAVE LOCAL
        const logsStr = localStorage.getItem("LOCAL_LOGS");
        let logs = logsStr ? JSON.parse(logsStr!) : undefined;
        if (!logs) {
            logs = [info];
        } else {
            logs.push(info);
        }
        localStorage.setItem("LOCAL_LOGS", JSON.stringify(logs))
    }

    callback();
  }
}


logger.add(new ATransporter());

Device.getInfo().then((info: any) => logger.defaultMeta.device = info);
Device.getId().then((id: any) => logger.defaultMeta.deviceID = id);

const getEnvironment = () => {
    const backendUrl = environment.BACKEND_URL;
    let env = undefined;

    if (backendUrl.includes('localhost')) { env = "local"}
    if (backendUrl.includes('acon360-dev')) { env = "dev"}
    if (backendUrl.includes('acon360-uat')) { env = "uat"}
    if (backendUrl.includes('acon360.acon-energie')) { env = "prod"}
    
    logger.defaultMeta.environment = env;
}

getEnvironment();
logger.defaultMeta.jwt = localStorage.getItem("token");
